@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body,
#root,
.app,
.content {
  /* height: 100%;  /* todos los elementos seleccionados ocuparán el 100% delcontenedor padre (pantalla)*/
  /* width: 100%; */
  align-items: center;
  align-content: center;
  /* Se define la fuente de todo el dashboard */
  font-family: "Inter", sans-serif;
}

:root {
  --color-blue50: #738cff;
  --color-blue100: #5e75f2;
  --color-blue200: #4a5cbf;
  --color-blue300: #3e4ea1;
  --color-blue400: #2a346b;
  --color-blue500: #1a2042;
}

/* Agrega esto en tu archivo CSS global o en un <style> tag si estás usando CSS-in-JS */
.roundTitle {
  position: relative;
  font-size: 0.9rem;
  align-self: center;
  font-family: Cabin;
  height: 1.8rem;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.313rem 0.8rem;
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  overflow: hidden;
}

.carousel-container {
  width: 100vw !important;
  margin: 0 !important;
  justify-content: center;
}

.roundTitle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 20px;
  background: linear-gradient(to right, lightblue, darkblue);
}

button:hover .hover-icon {
  opacity: 1;
}
