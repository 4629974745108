.carousel-contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.carousel-viewport {
  overflow: hidden;
  height: auto;
  width: 80%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

.carousel-contenido {
  display: flex;
  transition: transform 0.5s ease;
  width: calc(100% * 5 / 3); /* Por defecto, para pantallas grandes */
}

.carousel-caja {
  flex: 0 0 calc(33.333% - 4rem); /* Ocupa un tercio del ancho por defecto */
  margin: 0 2rem 3rem 2rem;
  padding: 3rem;
  aspect-ratio: 1;
  position: relative;
  background-color: #f5f5f9;
  background-size: calc(100% - 5rem); 
  background-position-y: 1rem;
  background-position-x: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  transition: transform 0.3s ease-in-out;
}

.carousel-caja:hover {
  transform: scale(1.025);
}

.carousel-caja::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%; 
  background: linear-gradient(to top, rgba(255, 255, 255, 1), transparent); 
  z-index: 1; 
}

.caja-texto {
  position: relative;
  z-index: 2;
  color: black;
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0px 0px -1rem -1rem;
  text-align: left;
  align-self: flex-start;
}

.flecha {
  background: white;
  border: none;
  width: 3rem;
  height: 3rem;
  font-size: 0;
  cursor: pointer;
  user-select: none;
  border-radius: 3rem;
  padding: 0;
  display: flex;
  align-items: calc(center - 3rem);
  justify-content: calc(center - 3rem);
}

.flecha-izquierda, .flecha-derecha {
  font-size: 2.25rem;
  color: #2f2f34;
  transition: all 0.3s ease;
}

.flecha:hover {
  color: #000;
}

.flecha:disabled {
  color: #fff;
  opacity: 0.5;
  cursor: default;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 600px) {
  .carousel-contenido {
    width: 100%; /* Cambiar el ancho para solo una imagen visible */
  }

  .carousel-caja {
    flex: 0 0 calc(100% - 0rem); /* Caja ocupa todo el ancho en pantallas pequeñas */
    margin: 0 0rem 2rem 0rem;
  }

  .flecha-izquierda, .flecha-derecha {
    font-size: 2rem;
    color: #2f2f34;
    transition: all 0.3s ease;
  }

  .flecha-izquierda {
    margin-right: -0.9rem;
  }
}
